import request from '../request'

//报销类目列表
export const reimbursement_category_index_request = p => {
    return request({
        method:'GET',
        url:'reimbursement_category/index',
        params: p
    })
}

//添加报销类目
export const reimbursement_category_add_request = d => {
    return request({
        method:'POST',
        url:'reimbursement_category/add',
        data: {
            name: d.name
        }
    })
}

//删除报销类目
export const reimbursement_category_del_request = id => {
    return request({
        method:'DELETE',
        url:'reimbursement_category/del',
        data: {
            id: id
        }
    })
}

//修改报销类目
export const reimbursement_category_edit_request = d => {
    return request({
        method:'PUT',
        url:'reimbursement_category/edit',
        data: {
            id: d.id,
            name: d.name
        }
    })
}

//获取报销类目详情
export const reimbursement_category_details_request = id => {
    return request({
        method:'GET',
        url:'reimbursement_category/details',
        params: {
            id
        }
    })
}